export const stringSorter = (
  a: string,
  b: string,
  ascending: boolean = true
) => {
  if (ascending) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  } else {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
  }
  return 0;
};
export const booleanSorter = (a: boolean, b: boolean) => {
  if (a && !b) {
    return -1;
  }
  return 1;
};
