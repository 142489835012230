import axios from 'axios';
import * as csrfToken from '../utils/csrftoken';
import { User } from '../interfaces/user';
import AppConfig from '../AppConfig';
import { getAxiosError } from '../utils/axios';
import { TIME_OUT } from '../utils/constants';

export const getHeaders = (csrfTokenVal?: string | null) => ({
  headers: {
    'X-CSRF-Token': csrfTokenVal,
  },
  timeout: TIME_OUT,
  withCredentials: true,
});

export const csrfTokenApi = () =>
  axios.get(`${AppConfig.apiEnv}/csrftoken`, { withCredentials: true });

export const loginApi = (username: string, password: string): Promise<User> => {
  const csrfTokenVal = csrfToken.getCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/login`,
      {
        username,
        password,
      },
      headers
    )
    .then((response) => {
      if (response.data.success && response.data.user) {
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const logoutApi = () => {
  const headers = getHeaders();
  const res = axios
    .get(`${AppConfig.apiEnv}/auth/logout`, headers)
    .then((response) => {
      if (response.data.success && response.data.time) {
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const loginStatusApi = () => {
  const headers = getHeaders();
  const res = axios
    .get(`${AppConfig.apiEnv}/auth/status`, headers)
    .then((response) => {
      if (response.data.success && response.data.user) {
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const forgotPasswordApi = (email: string): Promise<User> => {
  const csrfTokenVal = csrfToken.getCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/forgot-password`,
      {
        email,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const recoverPasswordApi = (
  token: string,
  newPassword: string
): Promise<User> => {
  const csrfTokenVal = csrfToken.getCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/reset-password`,
      {
        token,
        newPassword,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const signupApi = (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string
): Promise<User> => {
  const csrfTokenVal = csrfToken.getCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/signup`,
      {
        firstName,
        lastName,
        email,
        phone,
        password,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};
