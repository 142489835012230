import React from 'react';
import { Image, Typography } from 'antd';

import './WorkSteps.scss';
import step1 from '../../images/step1.png';
import step2 from '../../images/step2.png';
import step3 from '../../images/step3.png';

type Props = {};

const WorkSteps: React.FC<Props> = () => (
  <>
    <Typography.Title level={4}>How It Works</Typography.Title>

    <div className="WorkStepsTitle">
      A Simple 3-Step Process to Get Your Medications Delivered
    </div>
    <div className="WorkSteps">
      <div className="step">
        <Image src={step1} preview={false} className="stepImage" />
        <div className="stepTitle">Send Your Prescription</div>
        <p>
          Start by sending us your order easily through WhatsApp. Simply send a
          clear photo of prescription or name of the medicine.
        </p>
      </div>
      <div className="step">
        <Image src={step2} preview={false} className="stepImage" />
        <div className="stepTitle">We Prepare Your Order</div>
        <p>
          Our team of certified pharmacists will take care of the rest. Our team
          will contact you to confirm required details.
        </p>
      </div>
      <div className="step">
        <Image src={step3} preview={false} className="stepImage" />
        <div className="stepTitle">Order Delivered</div>
        <p>
          Your order will be delivered to your doorstep. You can also choose to
          pick it up from our pharmacy.
        </p>
      </div>
    </div>
  </>
);

export default WorkSteps;
