import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import {
  ReduxApiStatus,
  ReduxError,
  ReduxLoadingStatus,
} from '../../../interfaces/redux';
import {
  forgotPassword,
  loginStatus,
  loginUser,
  logoutUser,
  recoverPassword,
  signupUser,
} from './userActions';
import { User } from '../../../interfaces/user';

export enum UserLoginStatus {
  NOT_KNOWN = 'NOT_KNOWN',
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  FAILED = 'FAILED',
  LOGGED_OUT = 'LOGGED_OUT',
}

export interface UserState {
  data: { user?: User };
  apiStatus: {
    login: {
      status: UserLoginStatus;
      error?: any;
      errorNote?: string;
    };
    forgotPasswordApi?: ReduxApiStatus;
    recoverPasswordApi?: ReduxApiStatus;
    signupApi?: ReduxApiStatus;
  };
}

export const initialState: UserState = {
  data: {
    user: undefined,
  },
  apiStatus: {
    login: { status: UserLoginStatus.NOT_KNOWN },
    forgotPasswordApi: { loadingStatus: ReduxLoadingStatus.IDLE },
    recoverPasswordApi: { loadingStatus: ReduxLoadingStatus.IDLE },
    signupApi: { loadingStatus: ReduxLoadingStatus.IDLE },
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserData: () => initialState,
    clearApistatus: (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        forgotPasswordApi: { loadingStatus: ReduxLoadingStatus.IDLE },
        recoverPasswordApi: { loadingStatus: ReduxLoadingStatus.IDLE },
        signupApi: { loadingStatus: ReduxLoadingStatus.IDLE },
      },
    }),
    clearLoginStatus: (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        login: {
          status:
            state.apiStatus.login.status === UserLoginStatus.FAILED
              ? UserLoginStatus.NOT_KNOWN
              : state.apiStatus.login.status,
        },
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOADING },
      },
    }));
    builder.addCase(loginUser.fulfilled, (state, action) => ({
      ...state,
      data: { user: action.payload },
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOGGED_IN },
      },
    }));
    builder.addCase(loginUser.rejected, (state, action) => ({
      ...state,
      data: { user: undefined },
      apiStatus: {
        ...state.apiStatus,
        login: {
          status: UserLoginStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while login user`,
        },
      },
    }));

    builder.addCase(logoutUser.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOADING },
      },
    }));
    builder.addCase(logoutUser.fulfilled, (state) => ({
      ...state,
      data: { user: undefined },
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOGGED_OUT },
      },
    }));
    builder.addCase(logoutUser.rejected, (state) => ({
      ...state,
      data: { user: undefined },
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOGGED_OUT },
      },
    }));

    builder.addCase(loginStatus.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOADING },
      },
    }));
    builder.addCase(loginStatus.fulfilled, (state, action) => ({
      ...state,
      data: { user: action.payload },
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOGGED_IN },
      },
    }));
    builder.addCase(loginStatus.rejected, (state) => ({
      ...state,
      data: { user: undefined },
      apiStatus: {
        ...state.apiStatus,
        login: { status: UserLoginStatus.LOGGED_OUT },
      },
    }));

    builder.addCase(forgotPassword.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        forgotPasswordApi: { loadingStatus: ReduxLoadingStatus.LOADING },
      },
    }));
    builder.addCase(forgotPassword.fulfilled, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        forgotPasswordApi: { loadingStatus: ReduxLoadingStatus.COMPLETED },
      },
    }));
    builder.addCase(forgotPassword.rejected, (state, action) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        forgotPasswordApi: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error in password recovery`,
        },
      },
    }));

    builder.addCase(recoverPassword.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        recoverPasswordApi: { loadingStatus: ReduxLoadingStatus.LOADING },
      },
    }));
    builder.addCase(recoverPassword.fulfilled, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        recoverPasswordApi: { loadingStatus: ReduxLoadingStatus.COMPLETED },
      },
    }));
    builder.addCase(recoverPassword.rejected, (state, action) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        recoverPasswordApi: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error in password recovery`,
        },
      },
    }));

    builder.addCase(signupUser.pending, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        signupApi: { loadingStatus: ReduxLoadingStatus.LOADING },
      },
    }));
    builder.addCase(signupUser.fulfilled, (state) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        signupApi: { loadingStatus: ReduxLoadingStatus.COMPLETED },
      },
    }));
    builder.addCase(signupUser.rejected, (state, action) => ({
      ...state,
      apiStatus: {
        ...state.apiStatus,
        signupApi: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error in user signup, please try again`,
        },
      },
    }));
  },
});

export const { clearUserData, clearApistatus, clearLoginStatus } =
  userSlice.actions;

export const getUserStore = (state: RootState) => state.userStore;

export const getUser = (state: RootState) => state.userStore.data.user;

export * from './userActions';

export default userSlice.reducer;
