import React, { useEffect, useState } from 'react';
import { Divider, Typography } from 'antd';
import QRCode from 'qrcode';

import './order.scss';
import Faqs from '../../components/Faqs/Faqs';

const Order = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState<any>(null);

  const phoneNumber = '+919888011366'; // replace with your phone number
  const message =
    'Hello! I would like to order some medicines. Please check my prescription below and let me know the total cost.';
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  useEffect(() => {
    QRCode.toDataURL(whatsappUrl)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch(() => {});
  }, [whatsappUrl]);
  return (
    <>
      <Typography.Title level={3}>ORDER</Typography.Title>

      <br />
      <a href={whatsappUrl} className="webButton">
        Click here to start your order via WhatsApp
      </a>
      {qrCodeUrl && (
        <>
          <div className="orderOr">OR</div>
          <div className="orderTitle">
            Scan QR code below to send your order via WhatsApp
          </div>
          <img src={qrCodeUrl} alt="QR Code" />
        </>
      )}
      <div className="extraLinks">
        <a href="/how-it-works">How It Works</a> |
        <a target="_blank" href="/terms-and-condition">
          Terms & Conditions
        </a>
      </div>
      <Divider />
      <Faqs />
    </>
  );
};

export default Order;
