import React from 'react';

import './orderNow.scss';
import { useNavigate } from 'react-router-dom';

type Props = {};

const OrderNow: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <span
      className="orderNowButton"
      onClick={() => navigate('/order')}
      onKeyDown={() => navigate('/order')}
      role="button"
      tabIndex={0}
    >
      Order Now
    </span>
  );
};

export default OrderNow;
