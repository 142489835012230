import React from 'react';

import { StarFilled } from '@ant-design/icons';

import './customerFeedback.scss';
import { Typography } from 'antd';

type Props = {};

const CustomerFeedbackContent = [
  {
    title: 'Excellent Service',
    name: 'Harsh Mehta',
    content: `I've been purchasing my medications from this pharmacy for years
  and I've never been let down. The quality of the products is
  always top-notch and the customer service is exceptional!`,
  },
  {
    title: 'Reliable and Convenient',
    name: 'Deepali Sharma',
    content: `This pharmacy has made managing my prescriptions so much easier. Their
  home delivery service is incredibly convenient, and I appreciate the
  peace of mind knowing I can rely on them for my medication needs.`,
  },
  {
    title: 'Outstanding Customer Care',
    name: 'Rohini Pathak',
    content: `The staff at this pharmacy are always so helpful and knowledgeable.
  They take the time to answer my questions and make sure I understand
  my medications. I wouldn't trust my health with anyone else.`,
  },
];

const CustomerFeedback: React.FC<Props> = () => (
  <>
    <Typography.Title level={4} className="customerFeedbackTitle">
      Our Customer Feedback
    </Typography.Title>

    <div className="customerFeedback">
      {CustomerFeedbackContent.map((feedback) => (
        <div className="feedback" key={feedback.title}>
          <div className="feedbackTitle">{feedback.title}</div>
          <div className="feebackUser">
            {feedback.name}
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <StarFilled />
          </div>
          <p>{feedback.content}</p>
        </div>
      ))}
    </div>
  </>
);

export default CustomerFeedback;
