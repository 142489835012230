import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Typography } from 'antd';

import contact from '../../../images/contactpage.jpg';
import './sidebarSection.scss';

const { Paragraph } = Typography;
type Props = {};
const SidebarSection: React.FC<Props> = () => (
  <>
    <Image src={contact} preview={false} />
    {/* {current !== 'news' && ( */}
    <>
      <Typography.Title level={5}>Latest Updates</Typography.Title>
      <Paragraph className="news">
        Discover the newest updates on Smart Shop.
        <br />
        <Link to="/news" className="link">
          Latest News
        </Link>
      </Paragraph>
    </>
    {/* )} */}
  </>
);
export default SidebarSection;
