import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllApi,
  getByIdApi,
  addApi,
  updateApi,
  deleteByIdsApi,
  deleteByIdApi,
  getByFilterApi,
} from '../../../apis/commonApis';
import { ShoppingList } from '../../../interfaces/backend';
import { DeleteResponse } from '../../../interfaces/api';
import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';

const entityName = 'ShoppingList';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const getAllShoppingList = createAsyncThunk<ShoppingList[]>(
  `${entityNameLowerCase}/getAll`,
  async (_, { rejectWithValue }) => {
    try {
      return await getAllApi(entityName);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `${entityNameLowerCase}/getAll`,
      } as ReduxError);
    }
  }
);

export const getByIdShoppingList = createAsyncThunk<ShoppingList, string>(
  `${entityNameLowerCase}/getById`,
  async (id: string, { rejectWithValue }) => {
    try {
      return await getByIdApi(entityName, id);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/getById`,
      } as ReduxError);
    }
  }
);

export const getByFilterShoppingList = createAsyncThunk<
  ShoppingList[],
  Partial<ShoppingList>
>(
  `${entityNameLowerCase}/getByFIlter`,
  async (record: Partial<ShoppingList>, { rejectWithValue }) => {
    try {
      return await getByFilterApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/getByFIlter`,
      } as ReduxError);
    }
  }
);

export const addShoppingList = createAsyncThunk<
  ShoppingList,
  Partial<ShoppingList>
>(
  `${entityNameLowerCase}/add`,
  async (record: Partial<ShoppingList>, { rejectWithValue }) => {
    try {
      return await addApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/add`,
      } as ReduxError);
    }
  }
);

export const updateShoppingList = createAsyncThunk<
  ShoppingList,
  { id: string; record: Partial<ShoppingList> }
>(
  `${entityNameLowerCase}/update`,
  async ({ id, record }, { rejectWithValue }) => {
    try {
      return await updateApi(entityName, id, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id, record },
        url: `${entityNameLowerCase}/update`,
      } as ReduxError);
    }
  }
);

export const deleteByIdShoppingList = createAsyncThunk<string, string>(
  `${entityNameLowerCase}/deleteById`,
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteByIdApi(entityName, id);
      return id;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/deleteById`,
      } as ReduxError);
    }
  }
);

export const deleteByIdsShoppingList = createAsyncThunk<
  DeleteResponse[],
  string[]
>(
  `${entityNameLowerCase}/delete`,
  async (ids: string[], { rejectWithValue }) => {
    try {
      return await deleteByIdsApi(entityName, ids);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { ids },
        url: `/delete`,
      } as ReduxError);
    }
  }
);
