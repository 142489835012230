export const ShoppingListFields = ` 
    id
    name
    favourite
    notes 
    itemTags {
      id
      name
      color
    }
    items {
      id
      name
    }
    createdAt
    updatedAt
`;

export const ShoppingListAddFields1 = `$userId: Float!,$name: String!, $favourite: Boolean, $notes: String, $itemTags: [ItemTagInput!]`;
export const ShoppingListAddFields2 = `userId: $userId,name: $name,favourite:$favourite,notes:$notes, itemTags: $itemTags`;
export const ShoppingListUpdateFields1 = `$id: String!,$name: String, $favourite: Boolean, $notes: String, $itemTags: [ItemTagInput!]`;
export const ShoppingListUpdateFields2 = `id: $id, name: $name,favourite:$favourite,notes:$notes, itemTags: $itemTags`;
export const ShoppingListFilterFields1 = `$userId: Float!,$name: String, $favourite: Boolean `;
export const ShoppingListFilterFields2 = `userId: $userId,name: $name,favourite:$favourite `;

// ////////////////////////

export const ItemQuery = `
  id 
  shoppingList {
    ${ShoppingListFields}
  }
  name 
  quantityInStock 
  quantityToPurchase 
  unit  {
    id
    name
    abbreviation
  }
  purchased 
  itemTags {
    id
    name
    color
  } 
  notes 
  createdAt
  updatedAt
`;

export const ItemAddFields1 = `$shoppingListId: String!, $name: String!, $purchased: Boolean, $notes: String, $itemTags: [ItemTagInput!], $unitId: String, $quantityInStock: Float, $quantityToPurchase: Float`;
export const ItemAddFields2 = `shoppingListId: $shoppingListId,name: $name,purchased:$purchased,notes:$notes, itemTags: $itemTags, unitId: $unitId, quantityInStock: $quantityInStock, quantityToPurchase: $quantityToPurchase`;
export const ItemUpdateFields1 = `$id: String!,$shoppingListId: String, $name: String, $purchased: Boolean, $notes: String, $itemTags: [ItemTagInput!], $unitId: String, $quantityInStock: Float, $quantityToPurchase: Float`;
export const ItemUpdateFields2 = `id: $id, shoppingListId: $shoppingListId,name: $name,purchased:$purchased,notes:$notes, itemTags: $itemTags, unitId: $unitId, quantityInStock: $quantityInStock, quantityToPurchase: $quantityToPurchase`;
export const ItemFilterFields1 = ` $shoppingListId: String, $name: String, $purchased: Boolean `;
export const ItemFilterFields2 = ` shoppingListId: $shoppingListId,name: $name,purchased:$purchased `;

// ////////////////////////
export const UnitQuery = `
    id
    name
    abbreviation
`;

// ////////////////////////
export const ItemTagQuery = `
  id 
  name 
  color 
`;

// ////////////////////////
export const getQueryFields = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFields;
    case 'Unit':
      return UnitQuery;
    case 'ItemTag':
      return ItemTagQuery;
    case 'Item':
      return ItemQuery;
    default:
      return '';
  }
};

export const getAddFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListAddFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemAddFields1;
    default:
      return '';
  }
};

export const getAddFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListAddFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemAddFields2;
    default:
      return '';
  }
};

export const getUpdateFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListUpdateFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemUpdateFields1;
    default:
      return '';
  }
};

export const getUpdateFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListUpdateFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemUpdateFields2;
    default:
      return '';
  }
};

export const getFilterFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFilterFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemFilterFields1;
    default:
      return '';
  }
};

export const getFilterFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFilterFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemFilterFields2;
    default:
      return '';
  }
};
