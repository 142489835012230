import React from 'react';
import { Typography } from 'antd';

import blogsJson from '../../data/blogs.json';
import './news.scss';

const news = blogsJson;

const { Paragraph } = Typography;
const News = () => (
  <>
    <Typography.Title level={3} style={{ borderBottom: '1px solid orange' }}>
      BLOG
    </Typography.Title>
    <br />
    {news.map((article) => (
      <div className="news">
        <Paragraph>
          <div>
            <q>{article.title}</q>
            <span className="date">{article.date}</span>
          </div>
          {article.content}
        </Paragraph>
      </div>
    ))}
    <br />
    <br />
  </>
);
export default News;
