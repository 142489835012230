import React from 'react';
import { Typography } from 'antd';

import './home.scss';
import CustomerFeedback from '../../components/customerFeedback/customerFeedback';
import WorkSteps from '../../components/WorkSteps/WorkSteps';

const { Paragraph } = Typography;

const Home = () => (
  <>
    <Paragraph style={{ paddingTop: '1rem' }}>
      <q style={{ fontSize: '1.5rem' }}>
        Delivering thousands of prescriptions daily to our HAPPY CUSTOMER&apos;s
        Doorstep in Tricity
      </q>
    </Paragraph>

    <CustomerFeedback />

    <WorkSteps />
  </>
);

export default Home;
