import React from 'react';

import './about.scss';
import WorkSteps from '../../components/WorkSteps/WorkSteps';
import CustomerFeedback from '../../components/customerFeedback/customerFeedback';
import Faqs from '../../components/Faqs/Faqs';

const About = () => (
  <>
    <WorkSteps />
    <CustomerFeedback />
    <Faqs />
  </>
);

export default About;
