import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import './App.scss';
import colors from './colors.module.scss';
import WebTemplate from './template/web/web';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import News from './pages/news/news';
import About from './pages/about/about';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import { csrfTokenApi } from './apis/userApis';
import { addCsrfToken } from './utils/csrftoken';
import Order from './pages/order/order';
import TermsAndCondition from './pages/termsAndCondition/termsAndCondition';

const App = () => {
  useEffect(() => {
    csrfTokenApi()
      .then((res: any) => {
        addCsrfToken(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: colors.backgroundPrimary,
          colorTextBase: colors.textPrimary,
          colorPrimary: colors.linkActivePrimary,
          colorLink: colors.linkPrimary,
          colorTextHeading: colors.headingPrimary,
        },
        components: {
          Layout: {
            headerBg: colors.backgroundPrimary,
            bodyBg: colors.backgroundPrimary,
          },
        },
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<WebTemplate />}>
            <Route index element={<Home />} />
            <Route path="blog" element={<News />} />
            <Route path="how-it-works" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="order" element={<Order />} />
            <Route path="terms-and-condition" element={<TermsAndCondition />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};
export default App;
