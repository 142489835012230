import { gql } from 'apollo-boost';

import { DeleteResponse } from '../interfaces/api';
import {
  getAddFields1,
  getAddFields2,
  getFilterFields1,
  getFilterFields2,
  getQueryFields,
  getUpdateFields1,
  getUpdateFields2,
} from '../utils/graphqlFields';
import { client } from '../utils/graphqlclient';

// Helper function to recursively remove __typename
const removeTypename = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(removeTypename);
  }
  if (typeof value === 'object') {
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const getAllApi = async <T>(entityName: string): Promise<T[]> => {
  const res = await client
    .query({
      query: gql`
      query {
        getAll${entityName} {
         ${getQueryFields(entityName)}
        }
      } 
    `,
      fetchPolicy: 'network-only',
    })
    .then((response) => response.data[`getAll${entityName}`] as T[]);
  return res;
};

export const getByIdApi = async <T>(
  entityName: string,
  id: string
): Promise<T> => {
  const GET_ENTITY_BY_ID = gql`
    query getById${entityName}($id: String!) {
      getById${entityName}(id: $id) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .query({
      query: GET_ENTITY_BY_ID,
      variables: { id },
      fetchPolicy: 'network-only',
    })
    .then((response) => response.data[`getById${entityName}`] as T);
  return res;
};

export const getByFilterApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T[]> => {
  const FILTER_ENTITY = gql`
  query getByFilter${entityName} (${getFilterFields1(entityName)}) {
    getByFilter${entityName} (${getFilterFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: FILTER_ENTITY,
      variables: { ...record },
      fetchPolicy: 'no-cache',
    })
    .then((response) => response.data[`getByFilter${entityName}`] as T[]);
  return res;
};

export const addApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T> => {
  const ADD_ENTITY = gql`
    mutation create${entityName} (${getAddFields1(entityName)}) {
      create${entityName} (${getAddFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: ADD_ENTITY,
      variables: { ...record },
      fetchPolicy: 'no-cache',
    })
    .then((response) => response.data[`create${entityName}`] as T);
  return res;
};

export const updateApi = async <T>(
  entityName: string,
  id: string,
  record: Partial<T>
): Promise<T> => {
  const UPDATE_ENTITY = gql`
    mutation update${entityName} (${getUpdateFields1(entityName)}) {
      update${entityName} (${getUpdateFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;

  const res = await client
    .mutate({
      mutation: UPDATE_ENTITY,
      variables: { id, ...removeTypename(record) },
      fetchPolicy: 'no-cache',
    })
    .then((response) => response.data[`update${entityName}`] as T);
  return res;
};

export const deleteByIdApi = async (
  entityName: string,
  id: string
): Promise<string> => {
  const DELETE_ENTITY = gql`
    mutation deleteById${entityName} ($id: String!) {
      deleteById${entityName} (id: $id) 
    }
  `;

  const res = await client
    .mutate({
      mutation: DELETE_ENTITY,
      variables: { id },
      fetchPolicy: 'no-cache',
    })
    .then((response) => response.data[`deleteById${entityName}`] as string);
  return res;
};

export const deleteByIdsApi = async (
  entityName: string,
  ids: string[]
): Promise<DeleteResponse[]> => {
  const DELETE_ENTITY = gql`
  mutation deleteByIds${entityName} ($ids: [String!]!) {
    deleteByIds${entityName} (ids: $ids)  {
      id
      status
      message
    }
  }
`;

  const res = await client
    .mutate({
      mutation: DELETE_ENTITY,
      variables: { ids },
      fetchPolicy: 'no-cache',
    })
    .then(
      (response) =>
        response.data[`deleteByIds${entityName}`] as DeleteResponse[]
    );
  return res;
};
