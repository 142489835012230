import { configureStore } from '@reduxjs/toolkit';
import shoppingListReducer from './reducers/shoppingList/shoppingListSlice';
import itemReducer from './reducers/item/itemSlice';
import userReducer from './reducers/user/userSlice';
import unitReducer from './reducers/unit/unitSlice';

export const store = configureStore({
  reducer: {
    shoppingListStore: shoppingListReducer,
    itemStore: itemReducer,
    userStore: userReducer,
    unitStore: unitReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
