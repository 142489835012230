import React from 'react';
import { Typography } from 'antd';
import './footerSection.scss';
import { MailFilled, PhoneFilled } from '@ant-design/icons';

// import Donate from '../../images/donate.png';

const { Paragraph } = Typography;

const FooterSection = () => (
  <>
    <div className="footerSection">
      <Paragraph>
        <Typography.Title level={4} className="footerTitle">
          Benefits
        </Typography.Title>
        <ul>
          <li>Professional Recommendations</li>
          <li>Exclusive Deals</li>
          <li>Same day delivery</li>
          <li>Customer Satisfaction</li>
        </ul>
      </Paragraph>
    </div>
    <div className="footerSection">
      <Paragraph>
        <Typography.Title level={4} className="footerTitle">
          Our Mission
        </Typography.Title>
        Pioneering the future of pharmacies, we blend the expertise of
        professional pharmacists with user-friendly technology and the ease of
        home delivery. Our mission is to provide a seamless experience for our
        customers.
      </Paragraph>
    </div>
    <div className="footerSection">
      <Paragraph>
        <Typography.Title level={4} className="footerTitle">
          Contact Us
        </Typography.Title>
        <div className="footercontact">
          <MailFilled className="icon" />
          SCO 49-50-51, near CMC Hospital, Bridge Market, 17C, Sector 17,
          Chandigarh, 160017, India
        </div>
        <div className="footercontact">
          <a href="tel:+919888011366">
            <PhoneFilled className="icon" />
            Call: +91-9888011366
          </a>
        </div>
      </Paragraph>
    </div>
  </>
);

export default FooterSection;
