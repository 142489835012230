import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Image, Layout, Space } from 'antd';

import HeaderSection from './headerSection/headerSection';
import FooterSection from './footerSection/footerSection';

import './web.scss';
import SidebarSection from './sidebarSection/sidebarSection';
import { isBannerRequired, isSidebarRequired } from './webpages';
import useDeviceType from '../../hooks/useDeviceType/useDeviceType';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import {
  UserLoginStatus,
  getUserStore,
  loginStatus,
} from '../../redux/reducers/user/userSlice';

import banner from '../../images/banner.jpg';
import OrderNow from '../../components/orderNow/orderNow';

const { Header, Footer, Content } = Layout;

type Props = {};

const Template: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const userStore = useAppSelector(getUserStore);

  useEffect(() => {
    // if login status is not known check login status
    if (
      [UserLoginStatus.NOT_KNOWN].includes(userStore.apiStatus.login.status)
    ) {
      dispatch(loginStatus());
    }
  }, [dispatch, navigate, userStore.apiStatus.login.status]);

  const page = useMemo(() => {
    const { pathname } = location;
    if (pathname === '/') {
      return 'home';
    }
    if (['/signup', '/forgotpassword', '/privacy'].includes(pathname)) {
      return 'login';
    }
    return pathname.replace('/', '');
  }, [location]);

  const { isScreen } = useDeviceType();

  return (
    <Space
      direction="vertical"
      className={`webtemplate ${isScreen ? 'screen' : 'mobile'}`}
    >
      <Layout>
        <Header className="templateheader">
          <div className="templatenav">
            <HeaderSection page={page} />
          </div>
        </Header>
        {isBannerRequired(page) && (
          <div className="banner">
            <Image src={banner} preview={false} className="bannerImage" />
            <div className="bannerText">
              <div>
                <div className="discountText">
                  Get your exclusive 15% discount
                </div>
                <div>
                  <OrderNow />
                </div>
              </div>
            </div>
          </div>
        )}
        {isBannerRequired(page) && (
          <div className="bannerTextMobile">
            <div className="discountText">
              Get your exclusive 15% discount online
            </div>
            <div>
              <OrderNow />
            </div>
          </div>
        )}
        <Content>
          <div className="templatecontent">
            <div className="page">
              <Outlet />
            </div>
            {isSidebarRequired(page) && isScreen && (
              <div className="sidebar">
                <SidebarSection />
              </div>
            )}
          </div>
        </Content>
        <Footer className="footer">
          <FooterSection />
        </Footer>
        <Footer className="copyright">
          © Copyrights 2024 | SINGLA MEDICAL |
          <a
            href="https://www.innovastacks.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            Design & Development By InnovaStacks
          </a>
        </Footer>
      </Layout>
    </Space>
  );
};

export default Template;
