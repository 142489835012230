class AppConfig {
  public readonly stubbed?: boolean;

  public readonly apiEnv: string;

  public readonly debugGraphql: boolean;

  constructor() {
    this.stubbed = process.env.REACT_APP_STUBBED?.trim() === 'true';
    this.apiEnv = process.env.REACT_APP_API_ENV as string;
    this.debugGraphql = process.env.REACT_APP_DEBUG_GRAPHQL?.trim() === 'true';
  }
}

export default new AppConfig();
