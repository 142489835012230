import React from 'react';
import { Typography } from 'antd';

import './termsAndCondition.scss';

const { Paragraph } = Typography;

type Props = {};

/* eslint-disable  react/no-unescaped-entities */
const TermsAndCondition: React.FC<Props> = () => (
  <>
    <Typography.Title level={3}>Terms And Condition</Typography.Title>
    <Paragraph className="pagetext">
      <b>Last Revised: Feb 2, 2024</b>
    </Paragraph>
    <Paragraph className="pagetext">
      These Terms and Conditions govern your use of the Singla Medical website
      (referred to as "we", "us", or "our"). By accessing and using our website,
      you agree to abide by these Terms and Conditions.
    </Paragraph>
    <Paragraph className="pagetext">
      <b>Website Use</b>
    </Paragraph>
    <Paragraph className="pagetext">
      You agree to use our website for lawful purposes only. You must not use
      our website in any way that could harm, disable, overburden, or impair it.
    </Paragraph>
    <Paragraph className="pagetext">
      <b>Return Policy</b>
    </Paragraph>
    <Paragraph className="pagetext">
      <ul>
        <li>No Exchange/Return of Cosmetic & FMCG products.</li>
        <li>
          No exchange/return of cut strips and opened bottles/drops/tubes etc.
        </li>
        <li>
          Exchange/return of unused medicines is applicable within 30 days with
          original invoice
        </li>
        <li>
          No exchange/return for specialized medicines procured specifically on
          the basis of the order by a customer.
        </li>
      </ul>
    </Paragraph>
    <Paragraph className="pagetext">
      <b>Product Information</b>
    </Paragraph>
    <Paragraph className="pagetext">
      We strive to ensure that the product information on our website is
      accurate, but we do not guarantee its accuracy. Always consult with a
      healthcare professional before using any medication.
    </Paragraph>
    <Paragraph className="pagetext">
      <b>Changes to These Terms</b>
    </Paragraph>
    <Paragraph className="pagetext">
      We reserve the right to modify these Terms and Conditions at any time.
      Changes will be posted on this page, and your continued use of the website
      after changes have been posted will constitute your acceptance of the
      changes.
    </Paragraph>
    <Paragraph className="pagetext">
      <b>Contact Us</b>
    </Paragraph>
    <Paragraph className="pagetext">
      If you have any questions or concerns about this, please
      <a href="/contact">contact us</a>.
    </Paragraph>
    <br />
    <br />
  </>
);

export default TermsAndCondition;
