export const wait = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export enum FormatTextType {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
  CAPITALIZE = 'CAPITALIZE',
}
export const formatText = (text: string, type: FormatTextType) => {
  switch (type) {
    case FormatTextType.LOWER:
      return text.toLowerCase();
    case FormatTextType.UPPER:
      return text.toUpperCase();
    case FormatTextType.CAPITALIZE:
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    default:
      return text;
  }
};

export const generateUniqueName = (list: any[], name: string) => {
  const existingNames = list.map((item) => item.name.toLowerCase());
  let newName = name;
  let counter = 1;

  while (existingNames.includes(newName.toLowerCase())) {
    newName = `${name}${counter}`;
    counter += 1;
  }
  return newName;
};
