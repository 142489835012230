import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image, MenuProps, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import logo from '../../../images/logo.png';
import { webPages } from '../webpages';
import './headerSection.scss';

type Props = {
  page: string;
};

const HeaderSection: React.FC<Props> = ({ page }: Props) => {
  const navigate = useNavigate();
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(`/${e.key}`);
  };

  return (
    <div className="header">
      <Link to="/" className="logo">
        <Image src={logo} preview={false} />
      </Link>
      {/* <span className="titleCity">In TriCity</span> */}
      <Menu
        className="menu"
        onClick={onClick}
        selectedKeys={[page]}
        mode="horizontal"
        items={[...(webPages || [])]}
        overflowedIndicator={<MenuOutlined />}
      />
    </div>
  );
};

export default HeaderSection;
