import { MenuProps } from 'antd';

export const webPages: MenuProps['items'] = [
  {
    label: 'Home',
    key: 'home',
  },
  {
    label: 'Order',
    key: 'order',
  },
  {
    label: 'How It Works',
    key: 'how-it-works',
  },
  {
    label: 'Blog',
    key: 'blog',
  },
  {
    label: 'Contact',
    key: 'contact',
  },
];

export const isBannerRequired = (page: string) => page === 'home';

export const isSidebarRequired = (page: string) => page === 'about';
