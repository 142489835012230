import React from 'react';

import './Faqs.scss';
import { Collapse, CollapseProps, Typography } from 'antd';

import faqsJson from '../../data/faqs.json';

type Props = {};

const faqs: CollapseProps['items'] = faqsJson;

const Faqs: React.FC<Props> = () => (
  <>
    <Typography.Title level={4} className="FaqsTitle">
      FAQs
    </Typography.Title>
    <div className="Faqs">
      <Collapse items={faqs} />
    </div>
  </>
);

export default Faqs;
