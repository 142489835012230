import React from 'react';
import { Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import './contact.scss';
import Faqs from '../../components/Faqs/Faqs';

const { Paragraph } = Typography;

const Contact = () => (
  <div className="contact">
    <div>
      <Typography.Title level={3} className="contactheading">
        CONTACT US
      </Typography.Title>
      <div className="contacttext">
        <Paragraph>
          We would love to hear from you! <br /> If you have any feedback,
          comments, or suggestions, please reach out to us at the following.
        </Paragraph>
      </div>
      <Paragraph className="contactContact">
        <span className="contactTitle">
          <PhoneOutlined className="icon" />
          &nbsp; Call
        </span>
        <a href="tel:+91-9888011366">+91-9888011366</a>
      </Paragraph>
      <Paragraph>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.212288856143!2d76.78033367542093!3d30.740537885255726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed0b23e33e09%3A0x9b57d4f2dff54d4b!2sSingla%20Medical!5e0!3m2!1sen!2sau!4v1706830750506!5m2!1sen!2sau"
          className="map"
          allowFullScreen={false}
          style={{ height: '250px' }}
          loading="lazy"
          referrerPolicy="no-referrer"
          title="InnovaStacks"
        />
      </Paragraph>
      <Faqs />
    </div>
  </div>
);

export default Contact;
