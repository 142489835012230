export const getErrorMessage = (err: any) => {
  // if error is of type graphql error then either return message from result or return undefined
  if (typeof err === 'object' && (err.networkError || err.graphQLErrors)) {
    if (err.networkError?.result?.message) {
      return err.networkError.result.message;
    }
    if (err.graphQLErrors?.length) {
      return err.graphQLErrors[0].message;
    }
    return undefined;
  }
  // for any other error return error message (axios)
  return err.message;
};
