import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import {
  forgotPasswordApi,
  loginApi,
  loginStatusApi,
  logoutApi,
  recoverPasswordApi,
  signupApi,
} from '../../../apis/userApis';
import { User } from '../../../interfaces/user';

export const loginUser = createAsyncThunk<
  User,
  { username: string; password: string }
>(`user/login`, async ({ username, password }, { rejectWithValue }) => {
  try {
    return await loginApi(username, password);
  } catch (err: any) {
    return rejectWithValue({
      error: JSON.stringify(err),
      message: getErrorMessage(err),
      requestPayload: {},
      url: `user/login`,
    } as ReduxError);
  }
});

export const logoutUser = createAsyncThunk<boolean>(
  `user/logout`,
  async (_, { rejectWithValue }) => {
    try {
      return await logoutApi();
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/logout`,
      } as ReduxError);
    }
  }
);

export const loginStatus = createAsyncThunk<User>(
  `user/loginstatus`,
  async (_, { rejectWithValue }) => {
    try {
      return await loginStatusApi();
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/loginstatus`,
      } as ReduxError);
    }
  }
);

export const forgotPassword = createAsyncThunk<User, string>(
  `user/forgotpassword`,
  async (email, { rejectWithValue }) => {
    try {
      return await forgotPasswordApi(email);
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/forgotpassword`,
      } as ReduxError);
    }
  }
);

export const recoverPassword = createAsyncThunk<
  User,
  { token: string; password: string }
>(`user/recoverpassword`, async ({ token, password }, { rejectWithValue }) => {
  try {
    return await recoverPasswordApi(token, password);
  } catch (err: any) {
    return rejectWithValue({
      error: JSON.stringify(err),
      message: getErrorMessage(err),
      requestPayload: {},
      url: `user/recoverpassword`,
    } as ReduxError);
  }
});

export const signupUser = createAsyncThunk<
  User,
  {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
  }
>(
  `user/signup`,
  async (
    { firstName, lastName, email, phone, password },
    { rejectWithValue }
  ) => {
    try {
      return await signupApi(firstName, lastName, email, phone, password);
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/signup`,
      } as ReduxError);
    }
  }
);
