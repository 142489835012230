const TOKEN_KEY = 'csrftoken';

export const addCsrfToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const removeCsrfToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getCsrfToken = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return localStorage.getItem(TOKEN_KEY);
  }
  return '';
};
