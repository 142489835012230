import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { getCsrfToken } from './csrftoken';
import AppConfig from '../AppConfig';
import { TIME_OUT } from './constants';

const apolloCache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${AppConfig.apiEnv}/graphql`,
  headers: {
    'keep-alive': 'true',
  },
  fetchOptions: {
    credentials: 'include',
    timeout: TIME_OUT,
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-CSRF-Token': getCsrfToken(),
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!AppConfig.debugGraphql) return;
  if (graphQLErrors)
    graphQLErrors.map((graphQLError) =>
      console.log(`[GraphQL error]: ${JSON.stringify(graphQLError)}`)
    );
  if (networkError)
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
});

const authFlowLink = authLink.concat(errorLink);

export const client = new ApolloClient({
  cache: apolloCache,
  link: authFlowLink.concat(httpLink),
});
