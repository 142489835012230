export enum ReduxLoadingStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export interface ReduxApiStatus {
  loadingStatus: ReduxLoadingStatus;
  error?: any;
  errorNote?: string;
}

export interface ReduxCommonApisStatus {
  getAllApiStatus: ReduxApiStatus;
  getByIdApiStatus: ReduxApiStatus;
  getByFilterApiStatus: ReduxApiStatus;
  addApiStatus: ReduxApiStatus;
  updateApiStatus: ReduxApiStatus;
  deleteByIdApiStatus: ReduxApiStatus;
  deleteApiStatus: ReduxApiStatus;
}

export interface ReduxError {
  error: any;
  message: string;
  requestPayload: any;
  url: string;
}
